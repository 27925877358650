import { MappedAddressView } from '@zola-helpers/client/dist/es/api/marketplace';
import { SEARCHED_LOCATION_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorSearchUtils';
import { VendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import {
  deleteCookie,
  deleteLocalStorage,
  getCookie,
  getLocalStorage,
  setCookie,
} from '@zola-helpers/client/dist/es/util/storage';
import type { AddressView, SearchLocationView, USCityView } from '@zola/svc-marketplace-ts-types';

import { getStateSlug } from '~/components/common/form/addresses';
import type { LocationOrAllMarketsType } from '~/types/responseTypes';

import { formatLocation } from './formatters';

const SEARCHED_LOCATION_SLUG_KEY = 'zola_marketplace_selected_city_from_nav';
export const VENDOR_MOST_RECENT_SEARCH_URL_BY_VENDOR_TYPE_KEY =
  'zola_marketplace_vendor_search_urls';

export type MostRecentVendorSearches = {
  [key in VendorTaxonomyKey]?: string;
};

export const getVendorCookiedSearches = (): MostRecentVendorSearches => {
  const vendorSearch = getCookie(VENDOR_MOST_RECENT_SEARCH_URL_BY_VENDOR_TYPE_KEY);
  if (vendorSearch) {
    return JSON.parse(vendorSearch);
  }
  return {};
};

export const setMostRecentVendorSearches = (
  searchUrl: string,
  taxonomyKey: VendorTaxonomyKey | null | undefined
) => {
  // the search was not vendor specific, so we don't want to save it
  if (!taxonomyKey) return;

  const previousSavedSearches = getVendorCookiedSearches();
  const newSavedSearches = { ...previousSavedSearches };
  newSavedSearches[taxonomyKey] = searchUrl.replace('/wedding-vendors/search/', '');
  setCookie(VENDOR_MOST_RECENT_SEARCH_URL_BY_VENDOR_TYPE_KEY, JSON.stringify(newSavedSearches));
};

/**
 * Save the searched location in local storage as a JSON object
 */
export const setSearchedLocation = (location: LocationOrAllMarketsType) => {
  setCookie(SEARCHED_LOCATION_KEY, JSON.stringify(location));
};

/**
 * Remove the searched location from local storage
 */
export const removeSearchedLocation = () => {
  deleteCookie(SEARCHED_LOCATION_KEY);
};

/**
 * Get the searched location slug from local storage
 *
 * This is set when a couple uses the universal seach bar and selects a location or category
 */
export const getSearchedLocationSlug = () => {
  return getLocalStorage(SEARCHED_LOCATION_SLUG_KEY);
};

/**
 * Remove the searched location from local storage
 */
export const removeSearchedLocationSlug = () => {
  deleteLocalStorage(SEARCHED_LOCATION_SLUG_KEY);
};

export const ALL_MARKETS = {
  type: 'ALL_MARKETS',
  label: 'All locations',
  slug: 'all-locations',
} as const;

export const NO_MARKETS = {
  type: 'NO_MARKETS',
  label: '',
  slug: 'no-locations',
} as const;

/**
 * Determines whether the location is a populated search location object (state, market or city)
 *
 * @returns true if the location is a state, market or city
 * @returns false if the location is all markets, no markets, an empty object, or null
 */
export const isSearchLocation = (
  location?: LocationOrAllMarketsType | Record<string, never> | null
): location is SearchLocationView => {
  if (!location || !location.type) {
    return false;
  }

  return location.type !== 'ALL_MARKETS' && location.type !== 'NO_MARKETS';
};

/**
 * Get a best-guess search location for a city view
 */
export const getSearchLocationForCity = (city: USCityView): SearchLocationView => {
  return {
    type: 'CITY',
    label: formatLocation({ city: city.name, stateProvince: city.stateCode }),
    slug: city.slug,
    stateSlug: getStateSlug(city.stateCode),
    marketSlug: city.vendorMarket?.key || null,
    usCityId: city.id,
    storefrontSearchPayload: {
      vendorMarketId: city.vendorMarket?.id || null,
      city: city.name,
      stateProvince: city.stateCode,
    },
    geoCoordinates: {
      latitude: city.latitude,
      longitude: city.longitude,
    },
  };
};

/**
 * Get a best-guess search location for a mapped address view
 */
export const getSearchLocationForAddress = (
  address: AddressView | MappedAddressView
): SearchLocationView => {
  return {
    type: 'CITY',
    label: formatLocation({ city: address.city, stateProvince: address.stateProvince }),
    slug: address.possibleCitySlug || '',
    stateSlug: getStateSlug(address.stateProvince || undefined),
    marketSlug: null,
    usCityId: null,
    storefrontSearchPayload: {
      vendorMarketId: null,
      city: address.city,
      stateProvince: address.stateProvince,
    },
    geoCoordinates: {
      latitude: address.latitude,
      longitude: address.longitude,
    },
  };
};
