import { Fragment } from 'react';

import cx from 'classnames';

import { DateValue, formatDate } from '~/util/dateUtils';
import { formatCreditCount } from '~/util/formatters';

import styles from './creditPriceStyles.module.less';

interface CreditPriceFields {
  creditPrice: number | null;
  baseCreditPrice: number | null;
}
interface CreditPriceProps extends CreditPriceFields {
  paymentRequired: boolean;
}

export enum DiscountType {
  None = 'None',
  UnlimitedCredits = 'UnlimitedCredits',
  Other = 'Other',
}

export const determineInquiryDiscount = ({
  creditPrice: _c,
  baseCreditPrice: _b,
}: CreditPriceFields): DiscountType => {
  const creditPrice = _c || 0;
  const baseCreditPrice = _b || 0;

  let discountType = DiscountType.None;
  if (creditPrice === 0 && baseCreditPrice > 0) {
    discountType = DiscountType.UnlimitedCredits;
  } else if (creditPrice < baseCreditPrice) {
    discountType = DiscountType.Other;
  }

  return discountType;
};

export const formatUnlimitedCreditExpiration = (discountActiveUntil: DateValue | null): string => {
  if (!discountActiveUntil) return '';

  const format = 'MMM DD, YYYY';

  return formatDate(discountActiveUntil, format);
};

export const StrikeThroughPrice: React.FC = ({ children }) => {
  return (
    <del role="deletion" className={styles.strikeThrough}>
      <span className={styles.baseCreditPrice}>{children}</span>
    </del>
  );
};

export const DiscountText = (props: CreditPriceFields): string | null => {
  const { creditPrice } = props;
  const discountType = determineInquiryDiscount(props);
  switch (discountType) {
    case DiscountType.UnlimitedCredits:
      return 'Included with your unlimited plan';
    case DiscountType.Other:
      return formatCreditCount(creditPrice || 0);
    default:
      return null;
  }
};

export const CreditPrice = (props: CreditPriceProps): JSX.Element => {
  const { paymentRequired, creditPrice, baseCreditPrice } = props;

  if (!paymentRequired) {
    return <Fragment>free</Fragment>;
  }

  const discountType = determineInquiryDiscount(props);
  if (discountType === DiscountType.None) {
    return <Fragment>{formatCreditCount(creditPrice || 0)}</Fragment>;
  }

  return (
    // Not using modules here so this is easy to target from other rules to change the formatting in the span.
    <span className="discounted-inquiry-price">
      <StrikeThroughPrice>{formatCreditCount(baseCreditPrice || 0)}</StrikeThroughPrice>
      <ins role="insertion" className={cx('discounted-inquiry-price__dek', styles.discountText)}>
        {DiscountText(props)}
      </ins>
    </span>
  );
};
