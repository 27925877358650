import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import _get from 'lodash/get';

import { IUserContext } from '~/types/userContext';
import { arePlaceholderWeddingAccountNames, CouplesNameFields } from '~/util/coupleNames';

import { getDate } from './dateUtils';

export const isGuest = (userContext: IUserContext | UserContext | null) => {
  const { is_guest } = userContext || {};
  return is_guest === undefined || is_guest === true;
};

export const hasMarketplaceAccount = (userContext: IUserContext | null) => {
  const { has_marketplace_account } = userContext || {};
  return has_marketplace_account === true;
};

const getUserWeddingDate = (userContext: IUserContext | null) => {
  return userContext?.user_role_account_weddings?.[0]?.wedding_date || null;
};

export const getIsUserPostWedding = (userContext: IUserContext | null) => {
  const weddingDate = getUserWeddingDate(userContext);
  return weddingDate !== null && getDate(weddingDate) <= getDate();
};

export const getUserWeddingLocationHistory = (userContext: IUserContext | null) => {
  return userContext?.user_role_account_weddings?.[0]?.wedding_location_history || null;
};

export const arePlaceholderNames = (
  userContext: IUserContext | null,
  values: CouplesNameFields = {}
): boolean => {
  const isPlaceholderAccordingToBackend = _get(
    userContext,
    'user_role_account_weddings[0].empty_or_placeholder_couple_names',
    null
  );

  const namesArePlaceholders =
    isPlaceholderAccordingToBackend !== null
      ? isPlaceholderAccordingToBackend
      : arePlaceholderWeddingAccountNames(values);

  return namesArePlaceholders;
};
