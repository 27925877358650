import { experimentStates } from '@zola-helpers/client/dist/es/experiments';
import experimentFlagsHelper from '@zola-helpers/client/dist/es/util/experimentFlagsHelper';
import { UserContext } from '@zola-helpers/server/dist/es/@types/userContext';
import experimentsFlagsHelperSSR from '@zola-helpers/server/dist/es/experiments';

import type { IUserContext } from '~/types/userContext';

const { CONTROL } = experimentStates; // The control value is TEST-0

const EXPERIMENTS = [
  'logrocket-marketplace-lp',
  'logrocket-marketplace-venue-checkout',
  'marketplace-hide-boost-listing-link',
  'marketplace_featured_vendors_holdout', // PE-192 - Hold some couples out from seeing paid featured vendor slots
  'marketplace-clp-lp-links-to-srp',
  'marketplace-preauth-srp-pros-and-cons-release-ramp', // PE-997: Add pros and cons to pre-auth SRP
  'marketplace-preauth-srp-top-picks-release-ramp', // PE-997: Add top picks to pre-auth SRP
  'marketplace-mass-inquiries-recommendation-count',
  'marketplace-landing-page-quiz', // PE-1286: A/B/C test the quiz on the LP
] as const;

type MarketplaceExperiment = typeof EXPERIMENTS[number];

/** Record LogRocket sessions for a small percentage of users who end up on the LP */
export const LOGROCKET_MARKETPLACE_LP: MarketplaceExperiment = 'logrocket-marketplace-lp';

/** Record LogRocket sessions for a small percentage of venue checkouts */
export const LOGROCKET_MARKETPLACE_VENUE_CHECKOUT: MarketplaceExperiment =
  'logrocket-marketplace-venue-checkout';

type FallbackVariations = Record<
  MarketplaceExperiment,
  ReturnType<typeof experimentFlagsHelper.createExperimentFlags>
>;

// This sets up each experiment to use the TEST-0 value as the default in every environment
const FALLBACK_VARIATIONS: FallbackVariations = EXPERIMENTS.reduce((result, experiment) => {
  return {
    ...result,
    [experiment]: experimentFlagsHelper.createExperimentFlags(CONTROL, CONTROL, CONTROL),
  };
}, {} as FallbackVariations);

const { isUnderTest, getExperimentVariation, isInExperiment } =
  experimentFlagsHelper.makeExperimentFlags(FALLBACK_VARIATIONS);

export type ABCTestVariation = 'NOT_IN_TEST' | 'TEST-0' | 'TEST-1' | 'TEST-2';
export type ABTestVariation = 'NOT_IN_TEST' | 'TEST-0' | 'TEST-1';

export const getABCNode = <T = React.ReactNode>(
  userContext: IUserContext | null,
  experimentKey: MarketplaceExperiment,
  nodeA: T,
  nodeB: T,
  nodeC: T
): T => {
  // Calling this assigns a variation to the user, and tracks the experiment as viewed
  const variation = getExperimentVariation(experimentKey, userContext);
  switch (variation) {
    case 'TEST-2': {
      return nodeC;
    }
    case 'TEST-1': {
      return nodeB;
    }
    case 'NOT_IN_TEST':
    case 'TEST-0':
    default: {
      return nodeA;
    }
  }
};

export const getABNode = <T = React.ReactNode>(
  userContext: IUserContext | null,
  experimentKey: MarketplaceExperiment,
  nodeA: T,
  nodeB: T
): T => {
  // This is a hack, but possible because an A/B test won't use the TEST-2 variation
  return getABCNode(userContext, experimentKey, nodeA, nodeB, nodeA);
};

const EXPERIMENT_FLAGS = {
  getABCNode,
  isUnderTest: (
    experimentKey: MarketplaceExperiment,
    userContext: IUserContext | UserContext | null,
    triggerExperimentViewed?: boolean
  ) => isUnderTest(experimentKey, userContext, triggerExperimentViewed),
  getExperimentVariation: (
    experimentName: MarketplaceExperiment,
    userContext: IUserContext | null,
    triggerExperimentViewed?: boolean
  ) => getExperimentVariation(experimentName, userContext, triggerExperimentViewed),
  isInExperiment: (
    experimentName: MarketplaceExperiment,
    userContext: IUserContext | null,
    triggerExperimentViewed?: boolean
  ) => isInExperiment(experimentName, userContext, triggerExperimentViewed),
};

export default EXPERIMENT_FLAGS;

const FALLBACK_VARIATIONS_SSR = EXPERIMENTS.reduce((result, experiment) => {
  return {
    ...result,
    [experiment]: experimentsFlagsHelperSSR.createExperimentFlags(),
  };
}, {} as FallbackVariations);

const { getExperimentVariation: getExperimentVariationSSR } =
  experimentsFlagsHelperSSR.makeExperimentFlags(FALLBACK_VARIATIONS_SSR);

export const EXPERIMENT_FLAGS_SSR = {
  getExperimentVariation: (
    experimentName: MarketplaceExperiment,
    userContext: IUserContext | null,
    cookies: { [key: string]: string }
  ) => getExperimentVariationSSR(experimentName, userContext as UserContext, cookies),
};
