import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react';

import { VendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getBusinessCategory } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { NavigationClicked } from '@zola/tracking-contracts/src/events';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { CaretV2Icon } from '@zola/zola-ui/src/components/SvgIcons/CaretV2';

import cx from 'classnames';
import { Link as ReactLink } from 'react-router-dom';

import { logout } from '~/actions/vendorAccountActions';
import useCurrentStorefront from '~/components/common/hooks/useCurrentStorefront';
import CircleImage from '~/components/common/images/CircleImage';
import useCreditBalance from '~/hooks/vendors/useCreditBalance';
import { useAppDispatch, useAppSelector } from '~/reducers';
import { getCurrentStorefront } from '~/selectors/vendorAccountSelectors';
import { getCreditText } from '~/util/creditUtils';
import featureFlags from '~/util/featureFlags';
import { StandardLogFn } from '~/util/logger';

import { addNextLevelTrackingProps, useStorefrontMenuItems } from '../useNavigationLinks';
import { StorefrontList } from './StorefrontList';

import styles from './accountDropdown.module.less';

interface UseAccountDropdownProps {
  storefrontName: string | null;
  userName: string | null;
  taxonomyKey?: VendorTaxonomyKey;
}

interface UseAccountDropdownResult {
  firstRowText: string | null;
  secondRowText: string | null;
}

/**
 * Provides the first and second row of text for the account dropdown.
 *
 * @return {object} result
 * @return {string} result.firstRowText
 * @return {string} result.secondRowText
 */
export const useAccountDropdownText = ({
  storefrontName,
  userName,
}: UseAccountDropdownProps): UseAccountDropdownResult => {
  const { publishedAt } = useCurrentStorefront() || {};

  const { balance, discountActiveUntil, freeTrialEndsAt, autoRenew, paidPlanStartsAt } =
    useCreditBalance();
  const [firstRowText, setFirstRowText] = useState<string | null>('');
  const [secondRowText, setSecondRowText] = useState<string | null>('');

  useEffect(() => {
    if (publishedAt) {
      setFirstRowText(storefrontName);
    } else {
      setFirstRowText(userName);
    }
  }, [publishedAt, storefrontName, userName]);

  useEffect(() => {
    let text = getCreditText(Boolean(publishedAt), {
      balance,
      discountActiveUntil,
      freeTrialEndsAt,
      autoRenew,
      paidPlanStartsAt,
    });

    if (!text) {
      text = storefrontName;
    }

    setSecondRowText(text);
  }, [
    autoRenew,
    balance,
    discountActiveUntil,
    freeTrialEndsAt,
    paidPlanStartsAt,
    publishedAt,
    storefrontName,
  ]);

  return {
    firstRowText,
    secondRowText,
  };
};

export const AccountDropdown = ({
  trackingPosition,
}: {
  trackingPosition: number;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentStorefront = useAppSelector(getCurrentStorefront);
  const vendorAccount = useAppSelector((state) => state.vendorAccount);

  const fullName = vendorAccount ? vendorAccount.fullName : null;
  let storefrontName = null;
  let imageUrl = null;

  if (currentStorefront) {
    storefrontName = currentStorefront.name;
    imageUrl = currentStorefront.coverImageUrl ? `${currentStorefront.coverImageUrl}?w=100` : null;
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleMobileDropdown = () => {
    setIsOpen(!isOpen);
  };

  const { firstRowText, secondRowText } = useAccountDropdownText({
    storefrontName,
    userName: fullName,
    taxonomyKey: currentStorefront?.taxonomyKey,
  });

  const { menuItems: storefrontMenuItems, numStorefronts } = useStorefrontMenuItems();

  const baseTrackingProps = useMemo(() => {
    return {
      navigation_level_1: 'ACCOUNT',
      navigation_level_1_position: trackingPosition,
    };
  }, [trackingPosition]);

  /** Tracks a navigation event on something in the account menu _after_ the storefront list */
  const trackNonStorefrontClick = useCallback(
    (identifier: string, position: number, trackingProps: Partial<NavigationClicked> = {}) =>
      (event: React.SyntheticEvent) => {
        if (featureFlags.get('debugVendorNavigationClicked')) {
          event.stopPropagation();
          event.preventDefault();
        }

        trackNavigationClicked({
          navigation_type: 'CATEGORICAL',
          business_unit: 'VENDOR_EXPERIENCE',
          business_category: currentStorefront
            ? getBusinessCategory(currentStorefront.taxonomyKey)
            : 'UNATTRIBUTED',
          ...addNextLevelTrackingProps(baseTrackingProps, identifier, numStorefronts + position),
          ...trackingProps,
        });
      },
    [baseTrackingProps, currentStorefront, numStorefronts]
  );

  let menuTrackingIndex = 1;

  return (
    <div className={styles.accountDropdownWrapper}>
      <div className={styles.accountDropdown} data-testid="account-dropdown">
        <LinkV2
          className={styles.link}
          onClick={toggleMobileDropdown}
          role="button"
          data-testid="toggle-mobile-dropdown"
          noUnderline
        >
          <CircleImage className={styles.image} src={imageUrl || ''} lazyLoad={false} />
          <div className={styles.info} data-testid="account-info">
            <div className={styles.fullName}>{firstRowText}</div>
            <div className={styles.storefrontName}>{secondRowText}</div>
          </div>
          <div className={styles.caret}>
            <CaretV2Icon title="Caret" width={12} />
          </div>
        </LinkV2>
        {/* This is positioned absolutely */}
        <div
          className={cx(styles.dropdownMenu, { active: isOpen })}
          onClick={toggleMobileDropdown}
          role="presentation"
        >
          <Fragment>
            <StorefrontList items={storefrontMenuItems} trackingProps={baseTrackingProps} />

            {storefrontMenuItems.length > 0 && (
              <div key="storefront-separator" role="separator" className={styles.dropdownDivider} />
            )}

            <div className={styles.dropdownItem} role="listitem">
              <ReactLink
                className={cx(styles.link, styles.addStorefront)}
                to="/inspire/vendors/onboard"
                data-testid="add-listing"
                onClick={trackNonStorefrontClick('ADD_STOREFRONT', menuTrackingIndex++)}
              >
                Create a new listing
              </ReactLink>
            </div>
          </Fragment>
          {featureFlags.get('enableVendorUserManagement') && (
            <div className={styles.dropdownItem} role="listitem">
              <ReactLink
                className={styles.link}
                to="/inspire/vendors/user-management"
                data-testid="manage-access"
                onClick={trackNonStorefrontClick('MANAGE_ACCESS', menuTrackingIndex++)}
              >
                Manage access
              </ReactLink>
            </div>
          )}

          <div className={styles.dropdownItem} role="listitem">
            <ReactLink
              className={styles.link}
              to="/inspire/vendors/account"
              data-testid="account-settings"
              onClick={trackNonStorefrontClick('ACCOUNT_SETTINGS', menuTrackingIndex++)}
            >
              Account settings
            </ReactLink>
          </div>

          <div className={styles.dropdownItem} role="listitem">
            <ReactLink
              className={styles.link}
              to="/inspire/vendors/listing/badges"
              data-testid="your-badges"
              onClick={trackNonStorefrontClick('VENDOR_BADGES', menuTrackingIndex++)}
            >
              Your badges and awards
            </ReactLink>
          </div>

          <div className={styles.dropdownItem} role="listitem">
            <ReactLink
              className={styles.link}
              to="/inspire/vendors/refer-a-vendor"
              data-testid="refer-a-vendor"
              onClick={trackNonStorefrontClick('REFER_A_VENDOR', menuTrackingIndex++)}
            >
              Refer vendors to join Zola
            </ReactLink>
          </div>

          <div key="storefront-separator" role="separator" className={styles.dropdownDivider} />
          <div className={styles.dropdownItem} role="listitem">
            <LinkV2
              data-testid="vendor-logout"
              noTextTransform
              className={styles.link}
              role="button"
              onClick={(event) => {
                trackNonStorefrontClick('LOGOUT', menuTrackingIndex++, {
                  navigation_type: 'GLOBAL',
                })(event);
                if (!featureFlags.get('debugVendorNavigationClicked')) {
                  dispatch(logout()).catch(StandardLogFn);
                }
              }}
            >
              Log out
            </LinkV2>
          </div>
        </div>
      </div>
    </div>
  );
};
